const currentLocale = localStorage.getItem('locale') || 'en'
/* eslint-disable */
export default [
  {
    path: '/user/login',
    name: 'apps-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      sharedType: 'user',
      type: 'user',
    },
  },
  {
    path: '/user/forget-password',
    name: 'apps-forget-password',
    component: () => import('@/views/auth/ForgetPassword.vue'),
    meta: {
      layout: 'full',
      sharedType: 'user',
      type: 'user',
    },
  },
  {
    path: '/user/registration',
    name: 'apps-registration',
    component: () => import('@/views/auth/Registration.vue'),
    meta: {
      layout: 'full',
      sharedType: 'user',
      type: 'user',
    },
  },
  {
    path: '/order/:orderNo',
    name: 'store-order-view',
    component: () => import('@/views/users/StoreOrder.vue'),
    meta: {
      pageTitle: 'Order View',
      showBreadCrumb: false,
      canActivate: false,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/support',
    name: 'user-support',
    component: () => import('@/views/users/UserSupport.vue'),
    meta: {
      pageTitle: 'User Support',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/home',
    name: 'user-home',
    component: () => import('@/views/users/Home.vue'),
    meta: {
      layout: 'horizontal',
      pageTitle: 'Home',
      canActivate: false,
      contentClass: 'ecommerce-application home-user-landing',
      showBreadCrumb: false,
      showHorizontalMenu: false,
      type: 'user',
    },
  },
  {
    path: '/user',
    redirect: `/${currentLocale}/user`,
  },
  {
    path: '/affiliate',
    redirect: `/${currentLocale}/affiliate`,
  },
  {
    path: '/:locale/user',
    name: 'user-landingpage',
    component: () => import('@/views/UserLandingPage.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/:locale/affiliate',
    name: 'affiliate',
    component: () => import('@/views/Affilate.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/user-jvzoo',
    name: 'user-landingpage-jvzoo',
    component: () => import('@/views/UserLandingPageJvzoo.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/user/dashboard',
    name: 'user-dashboard',
    component: () => import('@/views/users/Dashboard.vue'),
    meta: {
      pageTitle: 'Home',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/transport',
    name: 'user-transport',
    component: () => import('@/views/users/Transport.vue'),
    meta: {
      pageTitle: 'Transport',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/delivery',
    name: 'user-delivery',
    component: () => import('@/views/users/Delivery.vue'),
    meta: {
      pageTitle: 'Delivery',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/order',
    name: 'user-order',
    component: () => import('@/views/users/Order.vue'),
    meta: {
      pageTitle: 'Order',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/services',
    name: 'user-services',
    component: () => import('@/views/users/Services.vue'),
    meta: {
      pageTitle: 'Services',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/profile',
    name: 'user-profile',
    component: () => import('@/views/users/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/wallet',
    name: 'user-wallet',
    component: () => import('@/views/users/Wallet.vue'),
    meta: {
      pageTitle: 'Wallet',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
      layout: 'vertical',
    },
  },
  {
    path: '/user/store/list/:store',
    name: 'apps-store-list',
    component: () => import('@/views/users/StoreList.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      pageTitle: 'Store List',
      showBreadCrumb: false,
      showPin: true,
      canActivate: false,
      contentClass: 'ecommerce-application',
      type: 'user',
      showLocation: true
    },
  },
  {
    path: '/user/store/details/:store',
    name: 'apps-store-details',
    component: () => import('@/views/users/StoreDetails.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      layout: 'horizontal',
      showBreadCrumb: false,
      pageTitle: 'Store Details',
      canActivate: false,
      showHorizontalMenu: false,
      showMobileView: true,
      contentClass: 'ecommerce-application ecommerce-application-2 home-user-landing store-ui-landing',
      type: 'user',
      sharedType: 'user',
      showLocation: true
    },
  },
  {
    path: '/user/store/terms-conditions/:storeSlug/:storeId',
    name: 'apps-store-terms-conditions',
    component: () => import('@/views/users/TermsAndCondition.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      layout: 'horizontal',
      showBreadCrumb: false,
      pageTitle: 'Store Details',
      canActivate: false,
      showHorizontalMenu: false,
      showMobileView: true,
      contentClass: 'ecommerce-application home-user-landing store-ui-landing',
      type: 'user',
    },
  },
  {
    path: '/user/provider/terms-conditions/:providerSlug/:providerId',
    name: 'apps-provider-terms-conditions',
    component: () => import('@/views/users/TermsAndCondition.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      layout: 'horizontal',
      showBreadCrumb: false,
      pageTitle: 'Store Details',
      canActivate: false,
      showHorizontalMenu: false,
      showMobileView: true,
      contentClass: 'ecommerce-application home-user-landing store-ui-landing',
      type: 'user',
    },
  },
  {
    path: '/user/store/order/:orderid',
    name: 'apps-store-order',
    component: () => import('@/views/users/StoreOrder.vue'),
    meta: {
      pageTitle: 'Store Order',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/services/subcategories/list/:serviceName',
    name: 'apps-subcategories-order',
    component: () => import('@/views/users/ServiceList.vue'),
    meta: {
      isServicePage: true,
      pageTitle: 'Services',
      showBreadCrumb: false,
      canActivate: false,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/services/providers/:mainService/:serviceName',
    name: 'apps-services-provider-order',
    component: () => import('@/views/users/ServiceDetails.vue'),
    meta: {
      isServicePage: true,
      contentRenderer: 'sidebar-left-detached',
      showBreadCrumb: false,
      showPin: true,
      pageTitle: 'Service Details',
      canActivate: false,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/service/provider/:serviceName',
    name: 'apps-service-provider',
    component: () => import('@/views/users/ProviderStoreDetails.vue'),
    meta: {
      isServicePage: true,
      layout: 'horizontal',
      pageTitle: 'Provider Store',
      showBreadCrumb: false,
      showHorizontalMenu: false,
      canActivate: false,
      showMobileView: true,
      contentClass: 'ecommerce-application ecommerce-application-2 home-user-landing store-ui-landing',
      type: 'user',
      sharedType: 'user',
    },
  },
  {
    path: '/user/checkout-shop/:storeName',
    name: 'apps-checkout',
    component: () => import('@/views/users/store/checkout/UserECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      canActivate: true,
      showBreadCrumb: false,
      contentClass: 'ecommerce-application',
      type: 'user',
      showLocation: true
    },
  },
  {
    path: '/user/service/checkout',
    name: 'apps-service-checkout',
    component: () => import('@/views/users/service/UserServiceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      isServicePage: true,
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/service/request/:requestId',
    name: 'apps-service-request',
    component: () => import('@/views/users/ServiceRequest.vue'),
    meta: {
      isServicePage: true,
      showBreadCrumb: false,
      pageTitle: 'Service Request',
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/phonepe/order/:orderId/:transactionId?',
    name: 'apps-phonepe-success',
    component: () => import('@/views/users/PhonePeSuccess.vue'),
    meta: {
      showBreadCrumb: false,
      pageTitle: 'Payment Successful',
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
      requestType: 'ORDER',
    },
  },
  {
    path: '/user/phonepe/service/:orderId/:transactionId?',
    name: 'apps-phonepe-service-success',
    component: () => import('@/views/users/PhonePeSuccess.vue'),
    meta: {
      showBreadCrumb: false,
      pageTitle: 'Payment Successful',
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
      requestType: 'SERVICE',
    },
  },
  {
    path: '/user/checkout/:orderId',
    name: 'apps-paypal-success',
    component: () => import('@/views/users/PayPalSuccess.vue'),
    meta: {
      showBreadCrumb: false,
      pageTitle: 'Payment Successful',
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
      requestType: 'ORDER',
    },
  },
  {
    path: '/user/service/checkout/:orderId',
    name: 'apps-paypal-service-success',
    component: () => import('@/views/users/PayPalSuccess.vue'),
    meta: {
      showBreadCrumb: false,
      pageTitle: 'Payment Successful',
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
      requestType: 'SERVICE',
    },
  },
  {
    path: '/user/messaging',
    name: 'user-messaging',
    component: () => import('@/views/users/Messages.vue'),
    meta: {
      pageTitle: 'User Messages',
      showBreadCrumb: false,
      canActivate: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      type: 'user',
    },
  },
  {
    path: '/user/shop/:shopName/:shopId',
    name: 'user-shop-share',
    component: () => import('@/views/shared/Share.vue'),
    meta: {
      layout: 'horizontal',
      pageTitle: 'Shop Share',
      canActivate: false,
      contentClass: 'ecommerce-application home-user-landing padding-top-share',
      showBreadCrumb: false,
      showHorizontalMenu: false,
      type: 'user',
    },
  },
  {
    path: '/user/provider/:providerName/:providerId',
    name: 'user-provider-share',
    component: () => import('@/views/shared/Share.vue'),
    meta: {
      layout: 'horizontal',
      pageTitle: 'Provider Share',
      canActivate: false,
      contentClass: 'ecommerce-application home-user-landing padding-top-share',
      showBreadCrumb: false,
      showHorizontalMenu: false,
      type: 'user',
    },
  },
  {
    path: '/user/ai/chat',
    name: 'user-ai-chat',
    component: () => import('@/views/users/ai/AIChat.vue'),
    meta: {
      pageTitle: 'User AI Chat',
      showBreadCrumb: false,
      canActivate: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application ai-chat-application',
      type: 'user',
    },
  },
  {
    path: '/user/ai/images',
    name: 'user-ai-images',
    component: () => import('@/views/users/ai/AIImages.vue'),
    meta: {
      pageTitle: 'User AI Images',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/ai/images/saved',
    name: 'user-ai-images-saved',
    component: () => import('@/views/users/ai/AISavedImages.vue'),
    meta: {
      pageTitle: 'User AI Saved Images',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/ai/code',
    name: 'user-ai-code',
    component: () => import('@/views/users/ai/AICode.vue'),
    meta: {
      pageTitle: 'User AI Code',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/ai/text-to-speech',
    name: 'user-ai-t2s',
    component: () => import('@/views/users/ai/AIT2S.vue'),
    meta: {
      pageTitle: 'User Text to Speech',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/ai/speech-to-text',
    name: 'user-ai-s2t',
    component: () => import('@/views/users/ai/AIS2T.vue'),
    meta: {
      pageTitle: 'User Speech To Text',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/ai/writer',
    name: 'user-ai-writer',
    component: () => import('@/views/users/ai/AiTemplates.vue'),
    meta: {
      pageTitle: 'User Templates',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/ai/templates/:slug',
    name: 'user-ai-generate-template',
    component: () => import('@/views/users/ai/GenerateAiTemplate.vue'),
    meta: {
      pageTitle: 'User Generate Template',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/ai/templates/edit/:id',
    name: 'user-edit-ai-generated-template',
    component: () => import('@/views/users/ai/GeneratedAiTemplate.vue'),
    meta: {
      pageTitle: 'User Edit Generate Template',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },

  {
    path: '/user/affiliation-overview',
    name: 'user-affiliation-overview',
    component: () => import('@/views/shared/affilation/AffilationOverview.vue'),
    meta: {
      pageTitle: 'Affiliate Overview',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/affiliation-payments',
    name: 'user-affiliation-payments',
    component: () => import('@/views/shared/affilation/payments/index.vue'),
    meta: {
      pageTitle: 'Affiliate Payments',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/affiliation-payouts',
    name: 'user-affiliation-payouts',
    component: () => import('@/views/shared/affilation/payouts/index.vue'),
    meta: {
      pageTitle: 'Affiliate Payouts',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/user/affiliation-earnings',
    name: 'user-affiliation-earnings',
    component: () => import('@/views/shared/affilation/earnings/index.vue'),
    meta: {
      pageTitle: 'Affiliate Earnings',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'user',
    },
  },
  {
    path: '/referral/:code',
    name: 'referral-code-page',
    component: () => import('@/views/ReferralCodeStore.vue'),
    meta: {
      layout: 'full',
    },
  },
]
