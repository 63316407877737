import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

const modelUrl = 'subscription/subscription-packages';
import {
    isEmpty
} from '@/utilities'
const state = {
    allSubscriptions: [],
    isSubscriptionLoaded: false,
    isSubscriptionCountryLoaded: false,
    countries: [],
    countryPrices: []
}

const getters = {
    allSubscriptions: state => state.allSubscriptions.sort((a,b) => new Date(a.created_at) < new Date),
    storeSubscriptions: state => state.allSubscriptions.filter(i => i.package_for === 'STORE' && (i.is_public || !i.is_custom)).sort((a,b) => new Date(a.created_at) < new Date(b.created_at)).sort((b,a) => a.package_amount < b.package_amount),
    providerSubscriptions: state => state.allSubscriptions.filter(i => i.package_for === 'PROVIDER' && (i.is_public || !i.is_custom)).sort((a,b) => new Date(a.created_at) < new Date(b.created_at)).sort((b,a) => a.package_amount < b.package_amount),
    userSubscriptions: state => state.allSubscriptions.filter(i => i.package_for === 'USER' && (i.is_public || !i.is_custom)).sort((a,b) => new Date(a.created_at) < new Date(b.created_at)).sort((b,a) => a.package_amount < b.package_amount),
    allStoreSubscriptions: state => state.allSubscriptions.filter(i => i.package_for === 'STORE').sort((a,b) => new Date(a.created_at) < new Date(b.created_at)).sort((b,a) => a.package_amount < b.package_amount),
    allProviderSubscriptions: state => state.allSubscriptions.filter(i => i.package_for === 'PROVIDER').sort((a,b) => new Date(a.created_at) < new Date(b.created_at)).sort((b,a) => a.package_amount < b.package_amount),
    allUserSubscriptions: state => state.allSubscriptions.filter(i => i.package_for === 'USER').sort((a,b) => new Date(a.created_at) < new Date(b.created_at)).sort((b,a) => a.package_amount < b.package_amount),
    getSubscription: state => uuid => state.allSubscriptions.find(i => i.uuid == uuid),
    isSubscriptionLoaded: state => state.isSubscriptionLoaded,
    countries: state => state.countries,
    isSubscriptionCountryLoaded: state => state.isSubscriptionCountryLoaded,
    findCountryPrices: state => uuid => state.countryPrices.filter(i => i.sp_uuid == uuid),
}

const actions = {
    createSubscriptionPackage(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`, payload)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("addNewSubscription", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },

    updateSubscriptionPackage(ctx, payload) {
        const uuid = payload.get('uuid')
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/${uuid}`, payload)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("updateSubscription", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },

    getSubscriptionPackage(ctx, uuid) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/${uuid}`)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("getSubscription", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
    getSubscriptionPackages(ctx) {
        const userCountryId = localStorage.getItem('userSelectedCountryId')
        const shopCountryId = localStorage.getItem('shopSelectedCountryId')
        const providerCountryId = localStorage.getItem('providerSelectedCountryId')
        const formData = new FormData;
        formData.append('user_geo_country', userCountryId)
        formData.append('shop_geo_country', shopCountryId)
        formData.append('provider_geo_country', providerCountryId)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}-list`, formData)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("getSubscriptions", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
    getCountries(ctx) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/subscription/subscription-packages-countries`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("getCountries", data.responseData)
                    }
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },
    getCountryPrices(ctx, uuid) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/subscription/subscription-packages-country-prices/${uuid}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("getContryPrices", {
                            uuid,
                            data: data.responseData
                        })
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
    deleteSubscriptionPackage(ctx, uuid) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/${uuid}`)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("removeSubscription", uuid)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getSubscriptions(state, params) {
        state.allSubscriptions = params
        state.isSubscriptionLoaded = true
    },
    getCountries(state, params) {
        if(!isEmpty(params) && Array.isArray(params)){
            state.countries = params
        }
        state.isSubscriptionCountryLoaded = true
    },
    getContryPrices(state, params) {
        const {uuid, data} = params
        if(!isEmpty(uuid) && !isEmpty(data) && Array.isArray(data)){
            state.countryPrices = state.countryPrices.filter(i => i.sp_uuid != uuid)
            state.countryPrices = state.countryPrices.concat(data)
        }
    },
    getSubscription(state, params) {
        state.allSubscriptions = state.allSubscriptions.filter(i => i.uuid != params.uuid)
        state.allSubscriptions.push(params)
    },
    addNewSubscription(state, params) {
        state.allSubscriptions.push(params)
    },
    updateSubscription(state, params) {
        state.allSubscriptions = state.allSubscriptions.filter(i => i.uuid != params.uuid)
        state.allSubscriptions.push(params)
    },
    removeSubscription(state, uuid) {
        state.allSubscriptions = state.allSubscriptions.filter(i => i.uuid != uuid)
    },
}

export const subscriptionPlan = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
