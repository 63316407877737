/* eslint-disable eol-last */
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    userAddressList: (() => {
      const items = []
      return items
    })(),
  },
  getters: {
    userCurrency: () => {
      if (window.SuperApp.getters.userInfo()) {
        return JSON.parse(window.SuperApp.getters.userInfo()).currency_symbol
      }
      return 'AED'
    },
    userSelectedAddress: () => {
      if (localStorage.userSelectedAddress) {
        return +localStorage.userSelectedAddress
      }
      return 0
    },
  },
  mutations: {
    UPDATE_USER_ADDRESS_LIST(state, addressList) {
      state.userAddressList = addressList
    },
  },
  actions: {
    usersMenu(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/menus${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userPromocodes(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/promocodes${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStoreTimeslots(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/timeslots?store_id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveStoreTimeslots(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/store/timeslot`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bookUserTableOnline(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/store/booktable`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userNotifications() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isUser() ? 'user' : 'provider'}/notification?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userOnGoingServices() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/ongoing`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserProfile() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isUser() ? 'user' : 'provider'}/profile`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserCountry() {
      if (window.SuperApp.cache['/user/countries']) {
        return new Promise(resolve => {
          resolve(window.SuperApp.cache['/user/countries'])
        })
      }
      const formData = new FormData()
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/countries`, formData)
          .then(response => {
            window.SuperApp.cache['/user/countries'] = response
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getUserAddressInfo() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/useraddress`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserCities() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/cities`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserCity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/city`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStoreSearch(ctx, searchCriteria) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/search/general?search=${searchCriteria}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserProfileInfo(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/profile`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getGoodTableData(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}${payload.url}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    viewTripHistory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/trips-history/service/${payload.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserStoreList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/list/${payload.baseUrl}?${payload.query}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserStoreDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/details/${payload.storeName}?${payload.query}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeCuisines(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/cusines/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/reviews/${payload.storeName}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storePhotos(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/photos/${payload.storeName}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeItems(ctx, payload) {
      if (!payload.extraFilters) {
        // eslint-disable-next-line no-param-reassign
        payload.extraFilters = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/items/${payload.storeName}${payload.extraFilters}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPromocodeList(ctx, payload) {
      if (!payload) {
        // eslint-disable-next-line no-param-reassign
        payload = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/promocodelist${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServicePromocodeList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/services/promocodelist`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServiceSubCategory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/service_sub_category/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServiceProvidersList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/services/providers/list/${payload.query}?${payload.filters}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderServiceDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/services/provider/details/${payload.query}?${payload.filters}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServiceProviderUserAddress(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/services/provider/user/addresses?${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServiceTimeslots(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/timeslots?${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bookServiceTimeslots(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/timeslot`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createServiceDispute(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/service/dispute`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createOrderDispute(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/order/dispute`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDisputeStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/service/disputestatus/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTripServiceHistory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/trips-history/service/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelServiceRequest(ctx, payload) {
      if (!payload.reason) {
        // eslint-disable-next-line no-param-reassign
        payload.reason = 'User cancelled it'
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/service/cancel/request`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelOrderRequest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/order/cancel/request`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeUserPassword(ctx, payload) {
      let baseUrl = 'user/password'
      if (window.SuperApp.getters.isAgent()) {
        baseUrl = 'provider/agent/password'
      } else if (window.SuperApp.getters.isProvider()) {
        baseUrl = 'provider/password'
      } else if (window.SuperApp.getters.isStore()) {
        baseUrl = 'shop/password'
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${baseUrl}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNewUserAddress(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/address/add`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserAddress(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/address/update`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUserAddress(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/user/address/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOrderDisputeStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/order/disputestatus/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOrderDetailById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/order/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getWalletList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/walletlist`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userApproveService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/services/approve`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUsersCard() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/card`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMoney(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/add/money`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMoneyToUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/wallet/transfer`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userDeletePaymentMethod(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/user/card/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserCard(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/card`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userTripHistory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/trips-history/transport/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    makeStripePayment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/stripe`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    completeStripePayment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/stripe/success`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    makeFlutterwavePayment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/flutterwave`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    completeFlutterwavePayment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/flutterwave/success`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    razorPayPayment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/razorpay/validate/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    razorPayPaymentService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/razorpay/validate/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    phonePePayment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/phonepe/${payload.query}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paypalPayment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/paypal/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    phonePePaymentService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/phonepe/${payload.query}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paypalPaymentService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/paypal/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    phonePeSuccess(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/phonepe/verify-payment/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paypalSuccess(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/paypal/checkout-success/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paypalServiceSuccess(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/paypal/service/checkout-success/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAddOnsInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/cart-addons/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderTimings(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/service/provider/timings${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addChats(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/chat`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChatsForUser(ctx, payload) {
      if (!payload.agent) {
        // eslint-disable-next-line no-param-reassign
        payload.agent = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.userType()}${payload.agent}/chat?admin_service=${payload.type}&id=${payload.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTicketSubCategory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/ticket-category/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createTicket(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/new-ticket`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTicketInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/ticketComments/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTicketComment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/newcomment`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTicket(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/tickets/${payload.key}/updateStatus?status=${payload.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeRating(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/service/rate`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeOrderRating(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/store/order/rating`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerRating(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/rate/serve`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postOrderReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/reviews`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postDeliveryOrderReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/delivery/reviews`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postServiceReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/services/reviews`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    retryPayment(ctx, payload) {
      payload.append('from_url', window.location.href)
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/payment/payment_retry`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserChat(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/chat/user`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserChat(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/chat${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserChatOverview() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/chat/user/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/chat/user/search?stext=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    knowledgeBaseCategory() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/knowledge/category/list?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    knowledgeBaseList(ctx, payload) {
      if (!payload) {
        // eslint-disable-next-line no-param-reassign
        payload = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/knowledge/list?page=all${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    knowledgeBaseListById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/knowledge/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeUserStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/online`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    ordersChat() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/chat/user/all/order`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    servicesChat() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/chat/user/all/service`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getBlogCommentById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/blog/comment/list?blog_id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBlogComments(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/blog/comments?blog_id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listBlogComments(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/blog/comment/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBlogComments(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/blog/comment/list`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBlogComments(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/blog/comment/list/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBlogComments(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/user/blog/comment/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateChatStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/chat/user/read`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    blogListById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/blog/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    knowledgeBaseLatest() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/knowledge/latest/questions`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    knowledgeBaseQuestionById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/knowledge/questions/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllBlogs() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/blog/list?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLatestBlogList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/blog/latest/list`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFAQListAll() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/faq/list?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    commonShareInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/common/qr?id=${payload.id}&type=${payload.type}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    termsAndConditionDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/static/page${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    rescheduleService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/services/reschedule`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    countryList() {
      if (!window.SuperApp.cache['/user/countries/all'] && window.SuperApp.constants.countries) {
        window.SuperApp.cache['/user/countries/all'] = {
          data: {
            statusCode: '200',
            responseData: window.SuperApp.constants.countries,
          },
        }
      }
      if (window.SuperApp.cache['/user/countries/all']) {
        return new Promise(resolve => {
          resolve(window.SuperApp.cache['/user/countries/all'])
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/countries/all`)
          .then(response => {
            window.SuperApp.cache['/user/countries/all'] = response
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    cityByCountry(ctx, payload) {
      if (!window.SuperApp.cache[`/user/cities/bycountry?country_id=${payload}`] && window.SuperApp.constants.city) {
        // eslint-disable-next-line eqeqeq
        const isCityExist = window.SuperApp.constants.city.find(x => x.country_id == payload)
        if (isCityExist) {
          window.SuperApp.cache[`/user/cities/bycountry?country_id=${payload}`] = {
            data: {
              statusCode: '200',
              responseData: isCityExist.cities.map(x => ({
                ...x,
                country_id: isCityExist.country_id,
              })),
            },
          }
        }
      }
      if (window.SuperApp.cache[`/user/cities/bycountry?country_id=${payload}`]) {
        return new Promise(resolve => {
          resolve(window.SuperApp.cache[`/user/cities/bycountry?country_id=${payload}`])
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/cities/bycountry?country_id=${payload}`)
          .then(response => {
            window.SuperApp.cache[`/user/cities/bycountry?country_id=${payload}`] = response
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCityByStateCountry(ctx, payload) {
      if (window.SuperApp.cache[`/user/cities/bycountry?${payload}`]) {
        return new Promise(resolve => {
          resolve(window.SuperApp.cache[`/user/cities/bycountry?${payload}`])
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/cities/bycountry?${payload}`)
          .then(response => {
            window.SuperApp.cache[`/user/cities/bycountry?${payload}`] = response
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getStateByCountry(ctx, payload) {
      if (window.SuperApp.cache[`/states/${payload}`]) {
        return new Promise(resolve => {
          resolve(window.SuperApp.cache[`/states/${payload}`])
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/states/${payload}`)
          .then(response => {
            window.SuperApp.cache[`/states/${payload}`] = response
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    menuCountryList() {
      if (window.SuperApp.cache['/user/countries/by_menu']) {
        return new Promise(resolve => {
          resolve(window.SuperApp.cache['/user/countries/by_menu'])
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/countries/by_menu`)
          .then(response => {
            window.SuperApp.cache['/user/countries/by_menu'] = response
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteUserAccount() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/delete_user`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shareBlog(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/blog/shares/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    likeBlog(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/blog/likes/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    currentCart() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/current/cart`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeStoreCart() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/totalremovecart`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProviderCart() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/services/totalremovecart`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
