/* eslint-disable eol-last */
import store from '@/store'
import { ref, watch, computed } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

let tokenExpiredCount = 0

export const useResponsiveAppLeftSidebarVisibility = () => {
  const mqShallShowLeftSidebar = ref(false)
  // Close Active Sidebars and other stuff when going in large device
  const currentBreakPoint = computed(() => store.getters['app/currentBreakPoint'])
  watch(currentBreakPoint, (val, oldVal) => {
    // Reset chats & contacts left sidebar
    if (oldVal === 'md' && val === 'lg') mqShallShowLeftSidebar.value = false
  })

  return {
    mqShallShowLeftSidebar,
  }
}

export const _ = {}

export const showLoader = () => {
  if (document.getElementById('loading-bg')) {
    document.getElementById('loading-bg').style = 'display: block'
  }
}

export const imageHelper = url => window.SuperApp.helper.image(url)

export const hideLoader = () => {
  if (document.getElementById('loading-bg')) {
    document.getElementById('loading-bg').style = 'display: none'
  }
}

export const showErrorNotification = (that, error, notificationComponent = null) => {
  if (error && error.response && error.response.data && (error.response.data.message || error.response.data.error)) {
    let messages = []
    if (error.response.data.message) {
      messages.push(error.response.data.message)
    }
    if (window.SuperApp.errorEnabled && error.response.data.error && typeof error.response.data.error === 'string') {
      messages.push(error.response.data.error)
    }
    if (window.SuperApp.errorEnabled && error.response.data.error && typeof error.response.data.error === 'object' && error.response.data.error.message) {
      messages.push(error.response.data.error.message)
    }
    if (messages.length === 0) {
      messages.push('Something went wrong')
    }
    messages = Array.from(new Set(messages))
    if (messages.join('\n').trim() === 'Token Expired' || messages.join('\n') === 'Unauthorised') {
      if (tokenExpiredCount >= 1) {
        return
      }
      tokenExpiredCount += 1
    }
    that.$toast({
      component: notificationComponent !== null ? notificationComponent : ToastificationContent,
      props: {
        title: messages.join('\n'),
        icon: 'XIcon',
        variant: 'danger',
      },
    })
  } else {
    if (Array.isArray(error) && (error.toString().join('\n').trim() === 'Token Expired' || error.toString().join('\n') === 'Unauthorised')) {
      if (tokenExpiredCount >= 1) {
        return
      }
      tokenExpiredCount += 1
    }
    that.$toast({
      component: notificationComponent !== null ? notificationComponent : ToastificationContent,
      props: {
        title: error.toString(),
        icon: 'XIcon',
        variant: 'danger',
      },
    })
  }
  hideLoader()
}

export const showSuccessNotification = (that, message, messageIcon = null) => {
  that.$toast({
    component: ToastificationContent,
    props: {
      title: message,
      icon: messageIcon === null ? 'CheckSquareIcon' : messageIcon,
      variant: 'success',
    },
  })
}

export const showDangerNotification = (that, message, messageIcon = null) => {
  that.$toast({
    component: ToastificationContent,
    props: {
      title: message,
      icon: messageIcon === null ? 'XIcon' : messageIcon,
      variant: 'danger',
    },
  })
}
