/* eslint-disable eol-last */
import adminModule from '@/views/admin/adminModule'
import authModule from '@/views/auth/authModule'
import providerModule from '@/views/provider/providerModule'
import storeModule from '@/views/store/storeModule'
import userECommerceStoreModule from '@/views/users/userECommerceStoreModule'
import userModule from '@/views/users/userModule'
import Vue from 'vue'
import Vuex from 'vuex'
import * as modules from "./modules"

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...modules,
    app,
    appConfig,
    verticalMenu,
    'app-auth': authModule,
    'app-user': userModule,
    'app-ecommerce': userECommerceStoreModule,
    'app-provider': providerModule,
    'app-store': storeModule,
    'app-admin': adminModule,
  },
  strict: process.env.DEV,
})
