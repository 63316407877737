/* eslint-disable */
export default [
  {
    path: '/provider/login',
    name: 'apps-provider-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      sharedType: 'provider',
      type: 'provider',
    },
  },
  {
    path: '/provider/registration',
    name: 'apps-provider-registration',
    component: () => import('@/views/auth/Registration.vue'),
    meta: {
      layout: 'full',
      sharedType: 'provider',
      type: 'provider',
    },
  },
  {
    path: '/provider/forget-password',
    name: 'apps-provider-forget-password',
    component: () => import('@/views/auth/ForgetPassword.vue'),
    meta: {
      layout: 'full',
      sharedType: 'provider',
    },
  },
  {
    path: '/provider/promocodes',
    name: 'provider-promocodes',
    component: () => import('@/views/provider/Promocodes.vue'),
    meta: {
      pageTitle: 'Promocodes',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/profile',
    name: 'provider-profile',
    component: () => import('@/views/provider/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/agent-schedules',
    name: 'agent-schedules',
    component: () => import('@/views/provider/AgentTimings.vue'),
    meta: {
      pageTitle: 'Agent Schedules',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/payment-config',
    name: 'provider-payment-config',
    component: () => import('@/views/provider/PaymentConfig.vue'),
    meta: {
      pageTitle: 'Payment Config',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/wallet',
    name: 'provider-wallet',
    component: () => import('@/views/provider/Wallet.vue'),
    meta: {
      pageTitle: 'Wallet',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/analytics',
    name: 'provider-analytics',
    component: () => import('@/views/provider/Analytics.vue'),
    meta: {
      pageTitle: 'Analytics',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/agents',
    name: 'provider-agents',
    component: () => import('@/views/provider/Agents.vue'),
    meta: {
      pageTitle: 'Agents',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/support',
    name: 'provider-support',
    component: () => import('@/views/provider/ProviderSupport.vue'),
    meta: {
      pageTitle: 'Provider Support',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/notifications',
    name: 'provider-notifications',
    component: () => import('@/views/provider/Notifications.vue'),
    meta: {
      pageTitle: 'Provider Notifications',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/order',
    name: 'provider-order',
    component: () => import('@/views/provider/Orders.vue'),
    meta: {
      pageTitle: 'Order',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/service/agent/view/:requestId',
    name: 'provider-agent-view',
    component: () => import('@/views/users/ServiceRequest.vue'),
    meta: {
      pageTitle: 'Provider Agent View',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/services',
    name: 'provider-services',
    component: () => import('@/views/provider/Services.vue'),
    meta: {
      pageTitle: 'Services',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/my-documents',
    name: 'provider-documents',
    component: () => import('@/views/provider/MyDocuments.vue'),
    meta: {
      pageTitle: 'My Documents',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/my-services',
    name: 'provider-my-services',
    component: () => import('@/views/provider/MyServices.vue'),
    meta: {
      pageTitle: 'My Services',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/requests',
    name: 'provider-requests',
    component: () => import('@/views/provider/Requests.vue'),
    meta: {
      pageTitle: 'Requests',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/booking',
    name: 'provider-bookings',
    component: () => import('@/views/provider/Bookings.vue'),
    meta: {
      pageTitle: 'Provider Booking',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/agent/requests',
    name: 'provider-agent-requests',
    component: () => import('@/views/provider/AgentRequests.vue'),
    meta: {
      pageTitle: 'Agent Requests History',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/agent/dashboard',
    name: 'provider-agent-dashboard',
    component: () => import('@/views/provider/AgentDashboard.vue'),
    meta: {
      pageTitle: 'Agent Dashboard',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/reviews',
    name: 'provider-reviews',
    component: () => import('@/views/store/StoreReviews.vue'),
    meta: {
      pageTitle: 'Provider Reviews',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/overviews',
    name: 'provider-overviews',
    component: () => import('@/views/store/Overviews.vue'),
    meta: {
      pageTitle: 'Provider Overviews',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/hrm-departments',
    name: 'provider-hrm-departments',
    component: () => import('@/views/shared/hrm/Department.vue'),
    meta: {
      pageTitle: 'HRM Departments',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/hrm-designation',
    name: 'provider-hrm-designation',
    component: () => import('@/views/shared/hrm/Designation.vue'),
    meta: {
      pageTitle: 'HRM Designation',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/hrm-attendance',
    name: 'provider-hrm-attendance',
    component: () => import('@/views/shared/hrm/Attendance.vue'),
    meta: {
      pageTitle: 'HRM Attendance',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/hrm-holidays',
    name: 'provider-hrm-holidays',
    component: () => import('@/views/shared/hrm/Holidays.vue'),
    meta: {
      pageTitle: 'HRM Holidays',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/hrm-shift',
    name: 'provider-hrm-shift',
    component: () => import('@/views/shared/hrm/OfficeShift.vue'),
    meta: {
      pageTitle: 'HRM Shift',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/hrm-payrolls',
    name: 'provider-hrm-payrolls',
    component: () => import('@/views/shared/hrm/Payrolls.vue'),
    meta: {
      pageTitle: 'HRM Payrolls',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/hrm-employees',
    name: 'provider-hrm-employees',
    component: () => import('@/views/shared/hrm/employees'),
    redirect: "/provider/hrm-employees/list",
    meta: {
      pageTitle: 'HRM Employees',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
    children: [
      {
        name: "provider-employees_list",
        path: "list",
        component: () => import('@/views/shared/hrm/employees/index_employee'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'provider',
        },
      },
      {
        name: "provider-store_employee",
        path: "store",
        component: () => import('@/views/shared/hrm/employees/employee_create'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'provider',
        },
      },
      {
        name: "provider-edit_employee",
        path: "edit/:id",
        component: () => import('@/views/shared/hrm/employees/employee_edit'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'provider',
        },
      },
      {
        name: "provider-detail_employee",
        path: "detail/:id",
        component: () => import('@/views/shared/hrm/employees/employee_details'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'provider',
        },
      },
    ]
  },
  {
    path: '/provider/hrm-leaves',
    component: () => import('@/views/shared/hrm/leaves'),
    redirect: "/provider/hrm-leaves/list",
    meta: {
      pageTitle: 'HRM Leaves',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
    children: [
      {
        name: "provider-hrm-leaves",
        path: "list",
        component: () => import('@/views/shared/hrm/leaves/leave_list'),
        meta: {
          pageTitle: 'Leaves',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'provider',
        },
      },
      {
        name: "provider-hrm-leave-types",
        path: "type",
        component: () => import('@/views/shared/hrm/leaves/leave_type'),
        meta: {
          pageTitle: 'Leaves',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'provider',
        },
      },
    ]
  },
  {
    path: '/provider/account/accounts',
    name: 'provider-account-accounts',
    component: () => import('@/views/shared/account/Accounts.vue'),
    meta: {
      pageTitle: 'Accounts',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/account/expense',
    name: 'provider-account-expenses',
    component: () => import('@/views/shared/account/expense/index_expense'),
    meta: {
      pageTitle: 'Accounts',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/account/expenses',
    name: 'provider-account-expenses',
    component: () => import('@/views/shared/account/expense/index_expense'),
    meta: {
      pageTitle: 'Expenses',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/account/expenses/categories',
    name: 'provider-account-expense-categories',
    component: () => import('@/views/shared/account/expense/category_expense'),
    meta: {
      pageTitle: 'Expense Categories',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/account/deposits',
    name: 'provider-account-deposits',
    component: () => import('@/views/shared/account/deposits/index_deposit'),
    meta: {
      pageTitle: 'Deposits',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/account/deposits/categories',
    name: 'provider-account-deposits-categories',
    component: () => import('@/views/shared/account/deposits/deposit_category'),
    meta: {
      pageTitle: 'Deposit Categories',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/account/transfer-money',
    name: 'provider-account-transfer-money',
    component: () => import('@/views/shared/account/TransferMoney'),
    meta: {
      pageTitle: 'Transfer Money',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/photos',
    name: 'provider-photos',
    component: () => import('@/views/store/Photos.vue'),
    meta: {
      pageTitle: 'Provider Photos',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/policies',
    name: 'provider-policies',
    component: () => import('@/views/store/Policies.vue'),
    meta: {
      pageTitle: 'Provider Policies',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },

  {
    path: '/provider/affiliation-overview',
    name: 'provider-affiliation-overview',
    component: () => import('@/views/shared/affilation/AffilationOverview.vue'),
    meta: {
      pageTitle: 'Affiliate Overview',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/affiliation-payments',
    name: 'provider-affiliation-payments',
    component: () => import('@/views/shared/affilation/payments/index.vue'),
    meta: {
      pageTitle: 'Affiliate Payments',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/affiliation-payouts',
    name: 'provider-affiliation-payouts',
    component: () => import('@/views/shared/affilation/payouts/index.vue'),
    meta: {
      pageTitle: 'Affiliate Payouts',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/affiliation-earnings',
    name: 'provider-affiliation-earnings',
    component: () => import('@/views/shared/affilation/earnings/index.vue'),
    meta: {
      pageTitle: 'Affiliate Earnings',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
    },
  },
  {
    path: '/provider/ai/images',
    name: 'provider-ai-images',
    component: () => import('@/views/users/ai/AIImages.vue'),
    meta: {
      pageTitle: 'Provider AI Images',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/ai/images/saved',
    name: 'provider-ai-images-saved',
    component: () => import('@/views/users/ai/AISavedImages.vue'),
    meta: {
      pageTitle: 'Provider AI Saved Images',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/ai/writer',
    name: 'provider-ai-writer',
    component: () => import('@/views/users/ai/AiTemplates.vue'),
    meta: {
      pageTitle: 'Provider Templates',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/ai/templates/:slug',
    name: 'provider-ai-generate-template',
    component: () => import('@/views/users/ai/GenerateAiTemplate.vue'),
    meta: {
      pageTitle: 'Provider Generate Template',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/ai/templates/edit/:id',
    name: 'provider-edit-ai-generated-template',
    component: () => import('@/views/users/ai/GeneratedAiTemplate.vue'),
    meta: {
      pageTitle: 'Provider Edit Generate Template',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/sla-settings',
    name: 'provider-sla-settings',
    component: () => import('@/views/sla/SlaSettings.vue'),
    meta: {
      pageTitle: 'Provider SLA Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/notification-settings',
    name: 'provider-notification-settings',
    component: () => import('@/views/notification-template/NotificationTemplateSettings.vue'),
    meta: {
      pageTitle: 'Provider Notification Template Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
  {
    path: '/provider/thirdparty-settings',
    name: 'provider-thirdparty-settings',
    component: () => import('@/views/thirdparty/ThirpartyConfig.vue'),
    meta: {
      pageTitle: 'Provider 3rd Party Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'provider',
      userType: 'provider',
    },
  },
]
