/* eslint-disable eol-last */
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const itemsCount = 0
      return itemsCount
    })(),
    cartItems: (() => {
      const items = []
      return items
    })(),
    entireCart: (() => {
      const items = []
      return items
    })(),
    onGoingServices: (() => {
      const items = []
      return items
    })(),
    isServicePage: (() => {
      const isService = false
      return isService
    })(),
    promocodes: (() => {
      const items = []
      return items
    })(),
    serviceRequestProvider: (() => {
      const items = null
      return items
    })(),
    editRequestProvider: (() => {
      const items = null
      return items
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_EDIT_PROVIDER_SERVICE_REQUEST(state, service) {
      state.editRequestProvider = service
    },
    UPDATE_PROVIDER_SERVICE_REQUEST(state, service) {
      state.serviceRequestProvider = service
    },
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
    UPDATE_CART_ITEMS(state, cart) {
      state.cartItems = cart
    },
    UPDATE_USER_PROMOCODES(state, promocodes) {
      state.promocodes = promocodes
    },
    UPDATE_ENTIRE_CART_ITEMS(state, cart) {
      state.entireCart = cart
    },
    UPDATE_IS_SERVICE_PAGE(state, isService) {
      state.isServicePage = isService
    },
    UPDATE_ONGOING_SERVICES(state, services) {
      state.onGoingServices = services
    },
  },
  actions: {
    fetchCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/cartlist`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServiceCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/services/cartlist`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCouponsCartProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/cartlist${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    verifyCheckoutAddress(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/store/check/delivery`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServiceCouponsCartProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/services/cartlist${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQuantity(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/store/addcart`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateServiceQuantity(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/services/addcart`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeServiceQuantity(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/services/removecart`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeFromCart(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/store/removecart`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeServiceFromCart(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/services/removecart`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkoutProducts(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/store/checkout`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeOrderDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/order/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeOrderDetailPublic(ctx, orderNo) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store-order/${orderNo}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeCuisines(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/store/cusines/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    serviceCheckout(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/services/checkout`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    servicePromoCodeList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/services/promocodelist`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServiceRequest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/request/services/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
