import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty, objToQueryParams } from '@/utilities';
const modelUrl = 'account';

const state = {
    expenses_categories: [],
    deposits_categories: [],
    employees: [],
    accounts: [],
    accBasicLoaded: false,
}

const getters = {
    accounts: state => state.accounts,
    expenses_categories: state => state.expenses_categories,
    deposits_categories: state => state.deposits_categories,
    employees: state => state.employees,
}

const actions = {

    getAccountBasic(ctx, params) {
        const paramsArr = [];
        if(typeof params === 'object'){
            Object.keys(params).forEach(i => {
                let _param = Array.isArray(params[i]) ? params[i].join(',') : params[i];
                paramsArr.push(`${i}=${_param}`);
            })
        }
        const subUrl = !isEmpty(paramsArr) ? `get-basic?${paramsArr.join('&')}` : `get-basic`;
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/${subUrl}`)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("getAccountBasic", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getAccountBasic(state, params) {
        const { 
            expenses_categories, 
            accounts, 
            deposits_categories, 
            employees 
        } = params;

        if(!isEmpty(deposits_categories)){
            state.deposits_categories = deposits_categories;
        }
        if(!isEmpty(expenses_categories)){
            state.expenses_categories = expenses_categories;
        }
        if(!isEmpty(accounts)){
            state.accounts = accounts;
        }
        if(!isEmpty(employees)){
            state.employees = employees;
        }
    },
    getDepartment(state, params) {
        state.allDepartments = state.allDepartments.filter(i => i.id != params.id)
        state.allDepartments.push(params)
    },
    addNewDepartment(state, params) {
        state.allDepartments.push(params)
    },
    updateDepartment(state, params) {
        state.allDepartments = state.allDepartments.filter(i => i.id != params.id)
        state.allDepartments.push(params)
    },
    removeDepartment(state, id) {
        state.allDepartments = state.allDepartments.filter(i => i.id != id)
    },
}

export const account = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
