/* eslint-disable eol-last */
export default {
  // Endpoints
  loginEndpoint: process.env.VUE_APP_API_BASE_END_POINT ? `${process.env.VUE_APP_API_BASE_END_POINT}/api` : 'https://stagingapi.momitnow.com/api',
  userLogin: '/user/login',
  userSocialLogin: '/user/social/login',
  providerLogin: '/provider/login',
  providerSocailLogin: '/provider/social/login',
  agentLogin: '/provider/agent/login',
  shopLogin: '/shop/login',
  shopSocailLogin: '/shop/social/login',
  adminLogin: '/admin/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  apiEndpoint: process.env.VUE_APP_API_BASE_END_POINT ? `${process.env.VUE_APP_API_BASE_END_POINT}/api` : 'https://stagingapi.momitnow.com/api',
  baseEndpoint: process.env.VUE_APP_API_BASE_END_POINT ? `${process.env.VUE_APP_API_BASE_END_POINT}` : 'https://stagingapi.momitnow.com',
  adminEndpoint: process.env.VUE_APP_ADMIN_END_POINT ? `${process.env.VUE_APP_ADMIN_END_POINT}` : 'https://admin.momitnow.com',
  frontendBaseUrl: process.env.VUE_APP_FRONT_END_BASE_URL ? process.env.VUE_APP_FRONT_END_BASE_URL : 'https://staging.momitnow.com',
  frontendBaseUrl: process.env.VUE_APP_FRONT_END_BASE_URL ? process.env.VUE_APP_FRONT_END_BASE_URL : 'https://staging.momitnow.com',
  // socketIO: 'http://localhost:5000',
  socketIO: process.env.VUE_APP_SOCKET_URL ? process.env.VUE_APP_SOCKET_URL : `https://stagingapi.momitnow.com:8125`,
  tokenType: 'Bearer',
  /**
   * Added for local testing, when we make the build it should be true in order to send the user to login in case it is 401
   */
  reloadOn401: true,
}
