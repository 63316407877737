import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty } from '@/utilities';
const modelUrl = 'affilation';

const state = {
    affilation: {},
    payments: [],
    payouts: [],
    payout_requests: [],
    earnings: [],
    loadedAffilation: false,
    loadedEarnings: false,
    loadedPayouts: false,
    loadedPayoutRequests: false,
    loadedPayments: false,
}

const getters = {

    affilation: state => state.affilation,
    payments: state => state.payments,
    payouts: state => state.payouts,
    payout_requests: state => state.payout_requests,
    earnings: state => state.earnings,

}

const actions = {
    updatePayout(ctx, params){
        if(isEmpty(params)) return false;
        const formData  = new FormData;
        Object.keys(params).forEach(k => {
            if(Array.isArray(params[k])){
                const paramArray = params[k]
                paramArray.forEach(pk => {
                    formData.append(`${k}[]`, pk)
                })
            }else{
                formData.append(k, params[k])
            }
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/payouts`, formData)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        ctx.commit('updatePayout', data.responseData)
                        return resolve(data)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    updateEarnings(ctx, params){
        if(isEmpty(params)) return false;
        const formData  = new FormData;
        Object.keys(params).forEach(k => {
            if(Array.isArray(params[k])){
                const paramArray = params[k]
                paramArray.forEach(pk => {
                    formData.append(`${k}[]`, pk)
                })
            }else{
                formData.append(k, params[k])
            }
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/earnings`, formData)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        ctx.commit('updateEarnings', data.responseData)
                    }
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },
    updatePayoutRequest(ctx, params){
        if(isEmpty(params)) return false;
        const formData  = new FormData;
        Object.keys(params).forEach(k => {
            formData.append(k, params[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/payout-requests`, formData)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        ctx.commit('updatePayoutRequest', data.responseData)
                    }
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },
    getAffilation(ctx){
        if(ctx.state.loadedAffilation) return new Promise(resolve => resolve(true));
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/affilation`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('getAffilation', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getPayments(ctx){
        if(ctx.state.loadedPayments) return new Promise(resolve => resolve(true));
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/payments`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('getPayments', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getPayouts(ctx){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/payouts`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('getPayouts', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getEarnings(ctx){
        if(ctx.state.loadedEarnings) return new Promise(resolve => resolve(true));
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/earnings`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('getEarnings', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getPayoutRequests(ctx){
        if(ctx.state.loadedEarnings) return new Promise(resolve => resolve(true));
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/payout-requests`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('getPayoutRequests', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getPayoutRequestSubItems(ctx, id){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/payout-request-details/${id}`)
                .then(response => {
                    const { data } = response
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getAffilation(state, data){
        state.affilation = data
        state.loadedAffilation = true
    },
    getPayments(state, data){
        state.payments = data
        state.loadedPayments = true
    },
    getPayouts(state, data){
        state.payouts = data
        state.loadedPayouts = true
    },
    getEarnings(state, data){
        state.earnings = data
        state.loadedEarnings = true
    },
    updateEarnings(state, earnings){
        if(!isEmpty(earnings) && Array.isArray(earnings)){
            state.earnings = state.earnings.filter(i => !earnings.some(j => j.id == i.id))
            state.earnings = state.earnings.concat(earnings)
        }
        state.loadedEarnings = true
    },
    getPayoutRequests(state, data){
        state.payout_requests = data
        state.loadedPayoutRequests = true
    },
    updatePayout(state, data){
        if(!isEmpty(data)){
            const ids = data.map(i => i.id)
            state.earnings = state.earnings.filter(i => !ids.some(k => j == i.id))
            state.payout_requests  = state.payout_requests.concat(data)
        }
    },
    updatePayoutRequest(state, data){
        if(!isEmpty(data)){
            state.payout_requests = state.payout_requests.filter(i => i.id != data.id)
            state.payout_requests.push(data)
        }
    },
}

export const affilation = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
