import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty, addNewFilter } from '@/utilities';

const state = {
    stores: []
}

const getters = {
    stores: state => state.stores.sort((a,b) => a.id - b.id),
    parentStores: (state, getters) => getters.stores.filter(i => isEmpty(i.parent_id)),
    childStoresByParentId: (state, getters) => parent_id => getters.stores.filter(i => i.parent_id == parent_id),
    availableStores: (state, getters) => getters.stores.filter(i => isEmpty(i.parent_id) && isEmpty(getters.childStoresByParentId(i.id))),
}

const actions = {
    getSlaManagement(ctx){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/sla`)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    saveSlaManagement(ctx, sla_management){
        const form_data = new FormData();
        form_data.append(`sla_management`, JSON.stringify(sla_management))
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/sla`, form_data)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getStores(ctx){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/admin/parent-stores`)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        ctx.commit('getStores',  data?.responseData)
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    saveStores(ctx, params){
        const form_data = new FormData()
        form_data.append('parent_id', params.parent_id)
        params.child.forEach(c => {
            form_data.append('child[]', c)
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/admin/parent-stores`, form_data)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        ctx.commit('getStores',  data?.responseData)
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getAgentEarningStatatics(ctx, params){
        const form_data = new FormData()
        Object.keys(params).forEach(k => {
            form_data.append(k, params[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/agent/earning-statitics`, form_data)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getAgentDashboardStatatics(ctx, params){
        const form_data = new FormData()
        Object.keys(params).forEach(k => {
            form_data.append(k, params[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/agent/dashboard-statitics`, form_data)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getSlaReportStatatics(ctx, params){
        const form_data = new FormData()
        Object.keys(params).forEach(k => {
            form_data.append(k, params[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/sla-reports-headings`, form_data)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    updaadteSlaReportStatatics(){
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/update-sla-reports-headings`)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getStores(state, data){
        state.stores = data
    }
}

export const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
