import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

const state = {
}

const getters = {
}

const actions = {
    updateStatus(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/provider/vechile/update-status`, payload)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
}

export const providerService = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
