import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty } from '@/utilities';
const modelUrl = 'subscription/coupons';

const state = {
    allCoupons: [],
    isCouponLoaded: false,
}

const getters = {
    allCoupons: state => state.allCoupons.sort((a,b) => new Date(a.created_at) < new Date(b.created_at)),
    isCouponLoaded: state => state.isCouponLoaded,
}

const actions = {
    createCoupon(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`, payload)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("addNewCoupon", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },

    applyCode(ctx, payload){
        const formData = new FormData;
        formData.append('coupon_code', payload)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/subscription/apply-code`, formData)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("addNewCoupon", data.responseData)
                    }
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },

    applPromoCode(ctx, payload){
        const formData = new FormData;
        formData.append('coupon_code', payload)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/subscription/apply-promo-code`, formData)
                .then(response => {
                    const { data } = response
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },

    getCoupons(ctx) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("getCoupons", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },

    deleteCoupon(ctx, uuid) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/${uuid}`)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("removeCoupon", uuid)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getCoupons(state, params) {
        state.allCoupons = params
        state.isCouponLoaded = true
    },
    addNewCoupon(state, params) {
        state.allCoupons = state.allCoupons.concat(params)
    },
    removeCoupon(state, uuid) {
        state.allCoupons = state.allCoupons.filter(i => i.uuid != uuid)
    },
}

export const coupon = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
