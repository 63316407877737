import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty } from '@/utilities';
const modelUrl = 'ai';

const state = {
    saveImagesLoaded: false,
    savedImages: [],
    saveT2SLoaded: false,
    savedT2S: [],
}

const getters = {
    savedImages: state => state.savedImages,
    saveImagesLoaded: state => state.saveImagesLoaded,
    savedT2S: state => state.savedT2S.sort((a,b) => a.id < b.id),
    saveT2SLoaded: state => state.saveT2SLoaded,
}

const actions = {
    getChats() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/chats`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },

    sendMessage(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/chats`, formData)
                .then(response => {
                    const { data } = response
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },

    generateAiImage(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/image`, formData)
                .then(response => {
                    const { data } = response
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },

    saveGeneratedAiImage(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/save-image`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("addUpdateSavedImages", [data.responseData])
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },

    getSavedImages(ctx, params){
        const _params = []
        Object.keys(params).forEach(k => {
            const value = isEmpty(params[k]) ? '' : params[k]
            _params.push(`${k}=${value}`);
        })
        const paramsStr = _params.join('&')
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/saved-images?${paramsStr}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(data)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },

    async aiSpeechToText(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/speech-to-text`, formData)
                .then(response => {
                    return resolve(response.data)
                })
                .catch(error => reject(error))
        })
    },

    async aiTextToSpeech(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/text-to-speech`, formData)
                .then(response => {
                    const { data }  = response
                    const { _statusCode }  = data
                    if(_statusCode === 200){
                        ctx.commit("addUpdateSavedT2S", [data.responseData])
                        return resolve(data)
                    }
                    return resolve(data.message)
                })
                .catch(error => reject(error))
        })
    },

    deleteSavedT2S(ctx, id){
        return new Promise((resolve, reject) => {
            axios
                .delete(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/delete-saved-t2s/${id}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(true)
                    }else{
                        return resolve(data?.message)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },

    deleteSavedS2T(ctx, id){
        return new Promise((resolve, reject) => {
            axios
                .delete(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/delete-saved-s2t/${id}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200){
                        return resolve(true)
                    }else{
                        return resolve(data?.message)
                    }
                })
                .catch(error => reject(error))
        })
    },
    
    deleteSavedCode(ctx, id){
        return new Promise((resolve, reject) => {
            axios
                .delete(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/delete-saved-code/${id}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(true)
                    }else{
                        return resolve(data?.message)
                    }
                })
                .catch(error => reject(error))
        })
    },

    getUsage(ctx){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/get-usage`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    addUpdateSavedImages: (state, data) => {
        const savedIds = state.savedImages.map(i => i.id)
        data.forEach(d => {
            if(savedIds.some(s => s == d.id)){
                state.savedImages = state.savedImages.map(i => i.id == d.id ? d : i)
            }else{
                state.savedImages.push(d)
            }
        })
    },
    imagesLoaded: state => {
        state.saveImagesLoaded = true
    },
    addUpdateSavedT2S: (state, data) => {
        const savedIds = state.savedT2S.map(i => i.id)
        data.forEach(d => {
            if(savedIds.some(s => s == d.id)){
                state.savedT2S = state.savedT2S.map(i => i.id == d.id ? d : i)
            }else{
                state.savedT2S.push(d)
            }
        })
    },
    t2sLoaded: state => {
        state.saveT2SLoaded = true
    },

}

export const ai = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
