/* eslint-disable eol-last */
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'

export default {
  namespaced: true,
  getters: {},
  actions: {
    userForgetPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${payload.query}/forgot/otp`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopForgetPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/forgotOtp`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerForgetPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/forgot/otp`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userSendOTP(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${payload.query}/send-otp`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userResetPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${payload.query}/reset/otp`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopResetPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/resetOtp`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopTypeList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/list/storetypes`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerServiceList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/user/providerservice/categories`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerResetPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/reset/otp`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userRegistration(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${payload.type}/signup`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopRegistration(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/create/signup`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerRegistration(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/create/signup`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    userVerifyOTP(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/verify-otp`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserCountry(ctx, payload) {
      if (window.SuperApp.cache['/user/countries']) {
        return new Promise(resolve => {
          resolve(window.SuperApp.cache['/user/countries'])
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/user/countries`, payload)
          .then(response => {
            window.SuperApp.cache['/user/countries'] = response
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
