import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

const modelUrl = 'subscription/payment-gateways';

const state = {
    paymentGateways: [],
    isPaymentGatewaysLoaded: false,    
}

const getters = {
    allPaymentGateways: state => state.paymentGateways.sort((a, b) => a.gateway.localeCompare(b.gateway)),
    activePaymentGateways: state => state.paymentGateways.filter(pg => pg.is_active == 1),
    isPaymentGatewaysLoaded: state => state.isPaymentGatewaysLoaded,
}

const actions = {
    updatePaymentGateway(ctx, payload) {
        const id = payload.get('id')
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/${id}`, payload)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("updatePaymentGateway", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
    getPaymentGateways(ctx) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("getPaymentGateways", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getPaymentGateways(state, params) {
        state.paymentGateways = params
        state.isPaymentGatewaysLoaded = true
    },
    updatePaymentGateway(state, params) {
        state.paymentGateways = state.paymentGateways.filter(i => i.id != params.id)
        state.paymentGateways.push(params)
    },
}

export const paymentGateway = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
