import { isUserLoggedIn } from '@/auth/utils'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { hideLoader, showDangerNotification, showLoader } from '@/@core/comp-functions/ui/app'
import admin from './routes/admin'
import provider from './routes/provider'
import store from './routes/store'
import users from './routes/users'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    console.log('Suspected Duplication Route')
  })
}
const currentLocale = localStorage.getItem('locale') || 'en'
const performBackButtonPreventation  = async (to, from) => {
  
  const loginPages = [
    'apps-admin-login',
    'apps-provider-login',
    'apps-shop-login',
    'apps-login',
  ];

  const dashboardPages = [
    'user-dashboard', 
    'provider-requests', 
    'provider-agent-dashboard', 
    'shop-dashboard', 
    'admin-cmspages-faq'
  ];

  const by_back_button = localStorage.getItem('by_back_button')
  let needConfirmation = false
  if (by_back_button == 1) {
    localStorage.removeItem('by_back_button')
    if (dashboardPages.includes(from.name) && loginPages.includes(to.name)) {
      needConfirmation = true;
    }
    if (needConfirmation) {
      const answer = confirm('Do you want to exit?');
      if (!answer) {
        window.history.pushState(null, '', window.location.href);
        return Promise.reject(false);
      }
    }
  }
  return Promise.resolve(true);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      redirect: { name: 'landing-page', params: {locale: currentLocale} },
    },
    {
      path: '/:locale/main',
      name: 'landing-page',
      component: () => import('@/views/Home.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/shop',
      redirect: { name: 'landing-shop-page', params: {locale: currentLocale} },
    },
    {
      path: '/:locale/shop',
      name: 'landing-shop-page',
      component: () => import('@/views/Shop.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/shop-jvzoo',
      name: 'landing-shop-page-jvzoo',
      component: () => import('@/views/ShopJvzoo.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/provider',
      redirect: { name: 'landing-provider-page', params: {locale: currentLocale} },
    },
    {
      path: '/:locale/provider',
      name: 'landing-provider-page',
      component: () => import('@/views/Provider.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/provider-jvzoo',
      name: 'landing-provider-page-jvzoo',
      component: () => import('@/views/ProviderJvzoo.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      redirect: { name: 'user-landingpage', params: {locale: currentLocale} },
    },
    ...users,
    ...provider,
    ...store,
    ...admin,
    {
      path: '/terms-and-condition',
      name: 'terms-and-condition',
      component: () => import('@/views/TermsAndCondition.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/PrivacyPolicy.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: () => import('@/views/Aboutus.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/views/Contactus.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/FAQ.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/knowledge-base',
      name: 'knowledge-base',
      component: () => import('@/views/KnowledgeBase.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/knowledge-base/category/:baseName/:baseId',
      name: 'blog-details-category',
      component: () => import('@/views/KnowledgeBaseBlogs.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/knowledge-base/details/:baseName/:categoryId/:knowledgeBaseId',
      name: 'knowledge-base-details-category',
      component: () => import('@/views/KnowledgeBaseDetails.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('@/views/blog/BlogList.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/blog/:blogName/:blogId',
      name: 'blog-details',
      component: () => import('@/views/blog/BlogDetail.vue'),
      meta: {
        layout: 'full',
        type: 'user',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  hideLoader()
})

router.beforeEach(async (to, from, next) => {
  try {
    // const proccedable = await performBackButtonPreventation(to, from);
    // if(!proccedable) return false;
    if(to.params.locale){
      localStorage.setItem('locale', to.params.locale || 'en')
    }
    const { canActivate, type } = to.meta
    showLoader()
    if (canActivate) {
      const isLoggedIn = isUserLoggedIn()
      if (!isLoggedIn) {
        if (type) {
          next({ path: `/${type}/login`, query: { returnUrl: to.path } })
        } else if (!isLoggedIn) {
          next({ path: '/user/login', query: { returnUrl: to.path } })
        }
      }
      if (isLoggedIn && to.name !== 'user-profile' && window.SuperApp.getters.isUser() && !window.SuperApp.validations.isUserDetailValid()) {
        next({ path: '/user/profile' })
        hideLoader()
        if (window.socketApp) {
          const force_subscribe = localStorage.getItem('userforce_subscribe') === 'true';
          if(!force_subscribe){
            showDangerNotification(window.socketApp, 'Please fill the required details!')
          }else{
            showDangerNotification(window.socketApp, 'Your subscription is expired. Please renew!')
          }
        }
        return
      }
      if (isLoggedIn && to.name !== 'shop-profile' && window.SuperApp.getters.isStore() && !window.SuperApp.validations.isShopDetailValid()) {
        next({ path: '/shop/profile' })
        hideLoader()
        if (window.socketApp) {
          const force_subscribe = localStorage.getItem('shopforce_subscribe') === 'true';
          if(!force_subscribe){
            showDangerNotification(window.socketApp, 'Please fill the required details to go online')
          }else{
            showDangerNotification(window.socketApp, 'Your subscription is expired. Please renew!')
          }
        }
        return
      }
      if (isLoggedIn && to.name !== 'provider-profile' && window.SuperApp.getters.isProvider() && !window.SuperApp.validations.isProviderDetailValid()) {
        next({ path: '/provider/profile' })
        hideLoader()
        if (window.socketApp) {
          const force_subscribe = localStorage.getItem('providerforce_subscribe') === 'true';
          if(!force_subscribe){
            showDangerNotification(window.socketApp, 'Please fill the required details to go online')
          }else{
            showDangerNotification(window.socketApp, 'Your subscription is expired. Please renew!')
          }
        }
        return
      }
    }else if(to.name == 'user-dashboard' || to.name == 'apps-store-details'){
      const isLoggedIn = isUserLoggedIn()
      if (isLoggedIn && to.name !== 'user-profile' && window.SuperApp.getters.isUser() && !window.SuperApp.validations.isUserDetailValid()) {
        next({ path: '/user/profile' })
        hideLoader()
        if (window.socketApp) {
          const force_subscribe = localStorage.getItem('userforce_subscribe') === 'true';
          if(!force_subscribe){
            showDangerNotification(window.socketApp, 'Please fill the required details!')
          }else{
            showDangerNotification(window.socketApp, 'Your subscription is expired. Please renew!')
          }
        }
        return
      }
    }
    Array.from(document.querySelectorAll('.mz-zoom-window'))
      .filter(x => x.remove)
      .map(x => x.remove())
    next()
  } catch (error) {
    // Stay on the current page
  }
  
})

export default router
