/* eslint-disable eol-last */
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'

export default {
  namespaced: true,
  actions: {
    getProviderProfile() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/profile`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderAgentProfile() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/profile`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProviderProfileInfo(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider${window.SuperApp.getters.isAgent() ? '/agent' : ''}/profile`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeProviderPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider${window.SuperApp.getters.isAgent() ? '/agent' : ''}/password`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderBankDetails() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/bankdetails/template`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editBankDetails(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/editbankdetails`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBankDetails(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/addbankdetails`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/list`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderCards() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/card`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderAgentById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAgentsProfile(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAgentsProfile(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/provider/agent/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAgentsProfile(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerDisputeStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/service/disputestatus/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createServiceDispute(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/history-dispute/service`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerTripServiceHistory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/history/service/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerAdminServices() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/adminservices`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerListDocument(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/listdocuments`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProviderDocument(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/provider/providerdocument/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadProviderDocument(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/documents`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerShopType() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/shoptype`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerRideType() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/ridetype`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerTotalServices() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/totalservices`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerVehicleAdd(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/vechile/add`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerVehicleEdit(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/vehicle/edit`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerVehicleAddService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/vechile/addservice`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerVehicleEditService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/vechile/editservice`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerRequestList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider${window.SuperApp.getters.isAgent() ? '/agent' : ''}/requests`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerRequestDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider${window.SuperApp.getters.isAgent() ? '/agent' : ''}/requests/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerRequestHistoryList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/history/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerAgentList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agents/list`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProviderRequest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProviderLocation(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/updatelocation`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    upateProviderAgentStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/${payload.id}/updateStatus?status=${payload.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePromocode(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/promocode/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createPromocode(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/promocode`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPromocodeInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/promocode/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePromocode(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/promocode/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paymentConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/payment/config`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePaymentConfig(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/payment/config`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createManualOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/manual/create_order`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchByPhoneNumber(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/get_user/by_phone?phone=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchByEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/get_user/by_email?email=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchByEmailForAgent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/get_user/by_email?email=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchByPhoneNumberForAgent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/get_user/by_phone?phone=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/create_user`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createUserAddress(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/create_user_address`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerCartList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/cartlist${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerAddCart(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/addcart`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerRemoveCart(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/removecart`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerAgentCartList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/cartlist${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerAgentAddCart(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/addcart`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerAgentRemoveCart(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/removecart`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderOrders(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/total/servicerequests${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTicketSubCategory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/ticket-category/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createTicket(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/new-ticket`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTicketInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/ticketComments/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTicketComment(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/newcomment`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTicket(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/tickets/${payload.key}/updateStatus?status=${payload.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bookedTimeSlots(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/timeslots${payload}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerServices() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/services`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerServicesForAgent() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/provider/services`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    acceptOrderRequest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/accept/request`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeCancelOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/cancel/request`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    agentOrders() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/history/order`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerNotification() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/notification`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getNotificationCount() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/notifications_count`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDeliveryType() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/deliverytype`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerAssignAgentToOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/assign`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeProviderStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/onlinestatus/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    currentOrders(ctx, payload) {
      if (!payload) {
        // eslint-disable-next-line no-param-reassign
        payload = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/order/agent/accepted_orders${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    currentServices(ctx, payload) {
      if (!payload) {
        // eslint-disable-next-line no-param-reassign
        payload = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/accepted_requests${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    incomingOrders(ctx, payload) {
      if (!payload) {
        // eslint-disable-next-line no-param-reassign
        payload = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/order/agent/all/orders${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    incomingServices(ctx, payload) {
      if (!payload) {
        // eslint-disable-next-line no-param-reassign
        payload = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/all/requests${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    acceptOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/order/agent/accept/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    ignoreOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/order/agent/ignore/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    acceptService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/accept/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    ignoreService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/ignore/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    agentUpdateOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/order/agent/update/order`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    agentUpdateService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/update/serve/request`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerNotifications(ctx, payload) {
      if (!payload) {
        // eslint-disable-next-line no-param-reassign
        payload = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/notification${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerAutoComplete(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/user-search?stext=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelReasonsAgent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/reasons?type=AGENT&service=${payload}&page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServiceForEditById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/service/cart/update?id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateServiceForEditById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/service/update?id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveProviderStaticPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/static/page`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderStaticPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/static/page${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAgentServiceForEditById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/service/cart/update?id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAgentServiceForEditById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/service/update?id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateQr(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/generate/qr`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    agentSendOtp(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/send/otp/update`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerSendOtp(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/send/otp/update`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    agentUpdateOrderReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/rate/order`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    agentUpdateServiceReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/rate/serve`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    providerPromocodes(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/promocodelist${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProviderTiming(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/agent/get/timing/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveProviderTiming(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/provider/agent/save/timing/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
