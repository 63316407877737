import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty, addNewFilter } from '@/utilities';
const modelUrl = 'shop/agents';

const state = {
    orders: [],
    online_agents: 0,
    offline_agents: 0,
    available_agents: 0,
    busy_agents: 0,
    agents: {},
    loadedOrders: [],
    ordersWithDetails: [],
    agentHours: [],
    agentHoursLoaded: false,
}

const getters = {
    orders: state => state.orders.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
    agents: state => state.agents,
    loadedOrders: state => state.loadedOrders,
    agentsByOrderId: state => orderId => {
        if(isEmpty(state.agents[orderId])) return [];
        return state.agents[orderId].filter(a => a.is_online == 1);
    },
    allAgentsByOrderId: state => orderId => {
        if(isEmpty(state.agents[orderId])) return [];
        return state.agents[orderId];
    },
    online_agents: state => state.online_agents,
    offline_agents: state => state.offline_agents,
    available_agents: state => state.available_agents,
    busy_agents: state => state.busy_agents,
    agentHours: state => state.agentHours,
}

const actions = {
    getTrackableOrders(ctx, orderId){
        const addiUrl = orderId ? `?order_id=${orderId}` : ''
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/trackable-orders${addiUrl}`)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        ctx.commit('getTrackableOrders', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getOrderTracking(ctx, orderId){
        if(ctx.state.loadedOrders.some(i => i == orderId)) return new Promise((resolve, reject) => resolve(true));
        const formData = new FormData();
        formData.append("order_id", orderId);
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/order-tracking`, formData)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        ctx.commit('getOrderTracking', {
                            data: data.responseData,
                            order_id: orderId
                        })
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getOrderDetails(ctx, orderId){
        if(ctx.state.ordersWithDetails.some(i => i.id == orderId)) return new Promise((resolve, reject) => resolve(ctx.state.ordersWithDetails.find(i => i.id == orderId)));
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/shop/order-details?order_id=${orderId}`)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        ctx.commit('getOrderDetails', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    changeOnlineStatus(ctx, status){
        const formData = new FormData();
        formData.append("is_online", Number(status));
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/provider/agents/change-online-status`, formData)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        const { responseData } = data
                        if(responseData){
                            const {agent} = responseData
                            window.SuperApp.getters.setUserInfo(JSON.stringify(agent))
                            return resolve(data)
                        }
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getAgentsHours(ctx){
        if(ctx.state.agentHoursLoaded) return false;
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/shop/agents/agent-hours`)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        const { responseData } = data
                        if (status === 200 && !isEmpty(responseData?.agents)) {
                            ctx.commit('getAgentsHours', responseData.agents)
                            return resolve(responseData?.timezone)
                        }
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getAgentsLocationHistory(ctx, {date, agent_id}){
        const form_data = new FormData();
        form_data.append('date', date)
        form_data.append('agent_id', agent_id)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/shop/agents/agent-locations`, form_data)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getTrackableOrders(state, data){
        if(isEmpty(state.orders)){
            state.orders = data?.orders ?? [];
        }else{
            state.orders = addNewFilter(state.orders, data.orders)
        }
        if(data?.online_agents){
            state.online_agents = data?.online_agents ?? 0;
            state.offline_agents = data?.offline_agents ?? 0;
            state.available_agents = data?.available_agents ?? 0;
            state.busy_agents = data?.busy_agents ?? 0;
        }
    },
    getOrderTracking(state, data){
        if(isEmpty(state.agents[data.order_id])){
            state.agents[data.order_id] = []
        }
        state.agents[data.order_id] = data.data
        state.loadedOrders.push(data.order_id)
    },
    getOrderDetails(state, data){
        state.ordersWithDetails = addNewFilter(state.ordersWithDetails, [data])
    },
    getAgentsHours(state, data){
        state.agentHours = addNewFilter(state.agentHours, data)
        state.agentHoursLoaded = true
    },
}

export const shopAgent = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
