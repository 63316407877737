import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty } from '@/utilities';
const modelUrl = 'admin/system-settings';

const state = {
    sysSettings: [],
    loadedSysSettings: false,
}

const getters = {
    sysSettings: state => state.sysSettings,
    sysSettingByKey: state => sysKey => state.sysSettings.find(i => i.key == sysKey)
}

const actions = {
    getSystemSettings(ctx){
        if(ctx.state.loadedSysSettings) return new Promise(resolve => resolve(true));
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('getSystemSettings', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    addSystemSettings(ctx, params){
        if(isEmpty(params)) return false;
        const formData  = new FormData;
        Object.keys(params).forEach(k => {
            formData.append(k, params[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('addSystemSettings', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    updateSystemSettings(ctx, params){
        if(isEmpty(params)) return false;
        if(isEmpty(params.id)) return false;
        const formData  = new FormData;
        Object.keys(params).forEach(k => {
            formData.append(k, params[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/${params.id}`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('updateSystemSettings', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getSystemSettings(state, data){
        state.sysSettings = data
    },
    addSystemSettings(state, data){
        state.sysSettings.push(data)
    },
    updateSystemSettings(state, data){
        state.sysSettings = state.sysSettings.filter(i => i.id != data.id)
        state.sysSettings.push(data)
    },
}

export const setting = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
