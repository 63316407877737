import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty, addNewFilter } from '@/utilities';

const state = {
    
}

const getters = {
    
}

const actions = {
    getThirdpartyConfigs(){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/thirdparty-config`)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    saveThirdpartyConfig(ctx, payload){
        const form_data = new FormData();
        Object.keys(payload).forEach(i => {
            form_data.append(i, payload[i])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/thirdparty-config`, form_data)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    updateThirdpartyConfig(ctx, payload){
        const id = payload.id
        delete payload.id
        return new Promise((resolve, reject) => {
            axios
                .patch(`${jwtDefaultConfig.apiEndpoint}/thirdparty-config/${id}`, payload)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    
}

export const thirdpartyConfig = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
