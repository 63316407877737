import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty } from '@/utilities';
const modelUrl = 'ai';

const state = {
    aiTemplatesLoaded: false,
    aiTemplateGroups: [],
    aiTemplates: [],
    aiGeneratedTemplates: [],
}

const getters = {
    aiTemplatesLoaded: state => state.aiTemplatesLoaded,
    aiTemplateGroups: state => state.aiTemplateGroups,
    aiTemplates: state => state.aiTemplates,
    aiTemplatesByGroupId: state => groupId => state.aiTemplates.filter(i => i.ai_template_group_id == groupId),
    aiTemplateBySlug: state => templateSlug => state.aiTemplates.find(i => i.slug == templateSlug),
    aiGeneratedTemplateById: state => id => state.aiGeneratedTemplates.find(i => i.id == id),
}

const actions = {
    storeNewTempalte(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/admin/ai-templates`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("customTemplateAddedUpdated", [data.responseData])
                        return resolve(data)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    updateTempalte(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        console.log(formData, 'formData');
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/admin/ai-templates/${payload.id}`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("customTemplateAddedUpdated", [data.responseData])
                        return resolve(data)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getAiGeneratedTemplate(ctx, id){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/get-ai-generated-template/${id}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("addUpdateGeneratedTemplate", [data.responseData])
                        return resolve(data)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getAiTemplateGroups(ctx){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/get-ai-templates`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("addUpdateAiGroupTemplates", data.responseData)
                        return resolve(data)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    generateTemplate(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/generate-ai-templates`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("addUpdateGeneratedTemplate", [data.responseData])
                        return resolve(data)
                    }
                    return resolve(data)
                })
                .catch(error => reject(error))
        })
    },
    saveTemplate(ctx, payload){
        const formData = new FormData;
        Object.keys(payload).forEach(k => {
            formData.append(k, payload[k])
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/save-generated-ai-template`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("addUpdateGeneratedTemplate", [data.responseData])
                        return resolve(data)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },

    deleteGeneratedTemplate(ctx, id){
        return new Promise((resolve, reject) => {
            axios
                .delete(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/delete-generated-template/${id}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(true)
                    }else{
                        return resolve(data?.message)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getAiTemplateBySlug(ctx, slug){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/get-ai-template/${slug}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("customTemplateAddedUpdated", data.responseData)
                        return resolve(data)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    addUpdateAiGroupTemplates: (state, groups) => {
        const savedGIds = state.aiTemplateGroups.map(i => i.id)
        const savedTIds = state.aiTemplates.map(i => i.id)
        groups.forEach(g => {
            if(!isEmpty(g.ai_templates)){
                g.ai_templates.forEach(t => {
                    if(savedTIds.some(s => s == t.id)){
                        state.aiTemplates = state.aiTemplates.map(j => j.id == t.id ? t : j)
                    }else{
                        state.aiTemplates.push(t)
                    }
                })
            }
            if(savedGIds.some(s => s == g.id)){
                state.aiTemplateGroups = state.aiTemplateGroups.map(i => i.id == g.id ? g : i)
            }else{
                state.aiTemplateGroups.push(g)
            }
        })
        state.aiTemplatesLoaded = true
    },
    addUpdateGeneratedTemplate: (state, templates) => {
        if(Array.isArray(templates)){
            const savedIds = state.aiGeneratedTemplates.map(i => i.id)
            templates.forEach(t => {
                if(savedIds.some(s => s == t.id)){
                    state.aiGeneratedTemplates = state.aiTemplateGroups.map(i => i.id == t.id ? t : i)
                }else{
                    state.aiGeneratedTemplates.push(t)
                }
            })
        }
    },
    customTemplateAddedUpdated: (state, ai_template) => {
        if(!isEmpty(ai_template)){
            state.aiTemplates = state.aiTemplates.filter(j => j.id != ai_template.id)
            state.aiTemplates.push(ai_template)
        }
    }
}

export const aiTemplate = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
