import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const moduleUrl = `admin/import-users`;

const state = {
}

const getters = {
}

const actions = {
    importUsers(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${moduleUrl}`, payload)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },
}

const mutations = {
}

export const importStoreProvider = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
