import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty } from '@/utilities';
const modelUrl = 'shop/warehouses';

const state = {
    wareHouses: [],
    loadedWareHouses: false,
}

const getters = {
    wareHouses: state => state.wareHouses,
    sotreWareHouses: state => {
        const store = window.SuperApp.getters.userInfo();
        if(isEmpty(store)) return [];
        return state.wareHouses.filter(i => i.store_id == store.id)
    },
    findWarehouse: state => id => state.wareHouses.find(i => i.id == id)
}

const actions = {
    getWareHouses(ctx){
        if(ctx.state.loadedWareHouses) return new Promise(resolve => resolve(true));
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('_getWareHouses', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    createWareHouse(ctx, params){
        if(isEmpty(params)) return false;
        const formData = new FormData()
        Object.keys(params).forEach(k => {
            if(Array.isArray(params[k])){
                const paramArray = params[k]
                paramArray.forEach(pk => {
                    formData.append(`${k}[]`, pk)
                })
            }else{
                formData.append(k, params[k])
            }
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('_createWareHouse', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    updateWareHouse(ctx, params){
        if(isEmpty(params)) return false;
        const formData = new FormData()
        Object.keys(params).forEach(k => {
            if(Array.isArray(params[k])){
                const paramArray = params[k]
                paramArray.forEach(pk => {
                    formData.append(`${k}[]`, pk)
                })
            }else{
                formData.append(k, params[k])
            }
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/${params.id}`, formData)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('_updateWareHouse', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    _getWareHouses(state, data){
        state.wareHouses = data
    },
    _createWareHouse(state, data){
        state.wareHouses = state.wareHouses.filter(i => i.id != data.id)
        state.wareHouses.push(data)
    },
    _updateWareHouse(state, data){
        state.wareHouses = state.wareHouses.filter(i => i.id != data.id)
        state.wareHouses.push(data)
    },
    _deleteWareHouse(state, data){
        state.wareHouses = state.wareHouses.filter(i => i.id != data.id)
    },
}

export const warehouse = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
