import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'

export default {
  namespaced: true,
  actions: {
    getFAQ(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/cmspages/faq/add`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFAQListAll() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/faq/list?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCMSPages() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/cmspage`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCMSPagesByText(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/cmspage${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCMSPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/cmspage/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/faq/categories?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategories(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/faq/categories`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategories(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/faq/categories/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCategoriesById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/faq/categories/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategoriesById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/admin/faq/categories/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFAQQuestions(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/faq/questions`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFAQQuestions(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/faq/questions/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFAQQuestionsById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/faq/questions/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFAQQuestionsById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/admin/faq/questions/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFAQ(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/faq/list${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFAQ(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/faq/list/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFAQById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/faq/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFAQById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/admin/faq/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBlog(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/blog/list${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBlog(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/blog/list/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getBlogById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/blog/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBlogById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/admin/blog/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllBlogs() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/blog/list?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getKnowledgeCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/categories?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addKnowledgeCategories(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/categories`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateKnowledgeCategories(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/categories/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getKnowledgeCategoriesById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/categories/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteKnowledgeCategoriesById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/categories/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getKnowledgeList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/list?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addKnowledgeList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/list`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateKnowledgeList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/list/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getKnowledgeListById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteKnowledgeListById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVariation(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/variations/list/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createVaraition(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/variations/list`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getVariationById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/variations/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVariation(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/admin/variations/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    adminStoreList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/get_stores_providers/SHOP`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateKnowledgeBaseQuestion(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/questions/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createKnowledgeBaseQuestions(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/questions`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getKnowledgeBaseQuestionsById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/questions/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteKnowledgeBaseQuestions(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/admin/knowledge/questions/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAdminTranslations(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/admin/translations?lang=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveAdminTranslations(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/admin/translations`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },
}
