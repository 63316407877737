import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty, dateDiff } from '@/utilities'

const modelUrl = 'subscribers';

const state = {
}

const getters = {
}

const actions = {
    initCheckout(ctx, payload) {
        
        const formData = new FormData;

        formData.append('new_sp_uuid', payload.uuid)
        formData.append('from_url', window.location.href)
        formData.append('payment_gateway', payload.payment_gateway)
        formData.append('code', payload.code)

        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/subscription/init-checkout`, formData)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
    verifyPayment(ctx, payload) {
        const formData = new FormData;
        formData.append('new_sp_uuid', payload.uuid)
        formData.append('from_url', window.location.href)
        formData.append('payment_gateway', payload.payment_gateway)
        formData.append('checkout_id', payload.checkout_id)
        formData.append('code', payload.code)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/subscription/verify-payment`, formData)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },

    userSubscriptions(ctx) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/subscription/user-subscription`)
                .then(response => {
                    const {data, status} = response
                    if(status === 200){
                        const subscriptions = data.responseData
                        ctx.commit("subscription/importFromUser", subscriptions, {root: true})
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },

    raiseCancelSubscription(ctx, shId) {
        const formData = new FormData
        formData.append('sh_id', shId)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/subscription/raise-cancel-subscription`, formData)
                .then(response => {
                    const {data, status} = response
                    if(status === 200){
                        const subscriptions = data.responseData
                        ctx.commit("subscription/importFromUser", subscriptions, {root: true})
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },

    cancelCancelSubscriptionRequest(ctx, shId) {
        const formData = new FormData
        formData.append('sh_id', shId)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/subscription/cancel-cancel-subscription-request`, formData)
                .then(response => {
                    const {data, status} = response
                    if(status === 200){
                        const subscriptions = data.responseData
                        ctx.commit("subscription/importFromUser", subscriptions, {root: true})
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },

    cancelSubscription(ctx, shId) {
        const formData = new FormData
        formData.append('sh_id', shId)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/subscription/cancel-subscription`, formData)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },
}

const mutations = {
}

export const subscriptionService = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
