import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty, dateDiff, dateTimeFormat } from '@/utilities'

const modelUrl = 'admin/subscribers';

const state = {
    isSubscribersLoaded: false,
    subscribers: [],
    storeSubscriptions: [],
    storePaymentMethods: [],
    allSubscriptions: [],
    providerPaymentMethods: [],
}

const getters = {
    isSubscribersLoaded: state => state.isSubscribersLoaded,
    listSubscribers: (state, getters, rootState, rootGetters) => state.subscribers.map(s => {
        const findPlan = rootGetters['subscriptionPlan/getSubscription']
        const user = s.userable
        const plan = findPlan(s.sp_uuid)
        const current_plan = plan?.package_name
        const current_plan_cost = `${plan?.package_amount} (per ${plan?.tenure_type?.toLowerCase()})`
        const user_name = `${user.first_name} ${user.last_name}`
        let restData = s
        delete restData.sp_uuid
        delete restData.userable
        return {
            ...restData,
            current_plan,
            current_plan_cost,
            user_name
        }
    }),
    subscriptionById: (state, getters, rootState, rootGetters) => (type, userId) => {
        let userable_type = null
        switch (type) {
            case 'STORE':
                userable_type = 'App\\Models\\Order\\Store';
                break;
            case 'PROVIDER':
                userable_type = 'App\\Models\\Common\\Provider';
                break;
            case 'USER':
                userable_type = 'App\\Models\\Common\\User';
                break;
            default:
                break;
        }
        const subs =  state.allSubscriptions.filter(i => i.userable_id == userId && i.userable_type == userable_type)
        return subs.map(sub => {
            if(!isEmpty(sub.sp_uuid)){
                let {order_balance, r_order_balance} = sub
                if(isEmpty(order_balance)){
                    order_balance = 0
                }
                if(isEmpty(r_order_balance)){
                    r_order_balance = 0
                }
                const order_used = order_balance - r_order_balance
                const getSubscription = rootGetters['subscriptionPlan/getSubscription']
                const plan = getSubscription(sub.sp_uuid)
                if(isEmpty(plan)) return false
                const daysRemaining = parseInt(dateDiff(new Date(), sub.end_date))
                const daysRemainingPer = daysRemaining * (10/3)
                const remainingOrders = parseInt(r_order_balance)
                const remainingOrdersPer = (order_used * 100) / parseInt(order_balance)
                const isActive = sub.subscription_status == 1 ? true : false
                const subStatus = parseInt(sub.subscription_status)
                let status_txt;
                switch (subStatus) {
                    case 1:
                        status_txt = 'Active'
                        break;
                    case 2:
                        status_txt = 'Cancelled'
                        break;
                    case 3:
                        status_txt = 'Upcoming'
                        break;
                    case 4:
                        status_txt = 'Cancellation Processing'
                        break;
                    default:
                        break;
                }
                return {
                    ...sub,
                    plan,
                    plan_name: plan?.package_name,
                    plan_amount: plan?.package_amount,
                    status_txt,
                    daysRemaining,
                    daysRemainingPer,
                    remainingOrders,
                    remainingOrdersPer,
                    start_date: dateTimeFormat(sub.start_date, 'DD MMM, YYYY'),
                    end_date: isEmpty(sub.end_date) ? 'Lifetime' : dateTimeFormat(sub.end_date, 'DD MMM, YYYY'),
                    isActive
                }
            }
            return false
        }).filter(i => i !== false)


    },
    paymentMethodsById: state => (type, userId) => {
        const sub = state.providerPaymentMethods.find(i => i.user_id == userId)
        return sub?.payment_methods
    },
}

const actions = {
    getSubscribers(ctx) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}`)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("getSubscribers", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
    storeSubscriptionCheckout(ctx, payload) {
        const formData = new FormData;
        formData.append('new_sp_uuid', payload.uuid)
        formData.append('from_url', window.location.href)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/shop/subscription-checkout`, formData)
                .then(response => {
                    const { data } = response
                    if (data.statusCode == '200') {
                        const store = window.SuperApp.getters.userInfo()
                        let uk = 'store'+store.id
                        localStorage.setItem(uk, data.responseData.id);
                        window.location.href = data.responseData.url
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
    providerSubscriptionCheckout(ctx, payload) {
        const formData = new FormData;
        formData.append('new_sp_uuid', payload.uuid)
        formData.append('from_url', window.location.href)
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/provider/subscription-checkout`, formData)
                .then(response => {
                    const { data } = response
                    if (data.statusCode === '200') {
                        ctx.commit("importFromUser", data.responseData)
                    }
                    return resolve(response)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getSubscribers(state, params) {
        state.subscribers = params
        state.isSubscribersLoaded = true
    },
    importFromUser(state, data){
        let subscriptions = data?.subscription_histories
        if(isEmpty(subscriptions) && !Array.isArray(subscriptions)){
            subscriptions = data
        }
        if (!isEmpty(subscriptions)) {
            subscriptions.forEach(sh => {
                if(state.allSubscriptions.some(i => i.id == sh.id)){
                    state.allSubscriptions = state.allSubscriptions.filter(i => i.id != sh.id)
                }
                state.allSubscriptions.push(sh)
            })
        }
    },
}

export const subscription = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
