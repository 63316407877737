import moment from "moment"
/**
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isEmpty(value) {
  return (
    value === undefined ||
    value === 'undefined' ||
    value === null ||
    value === 'null' ||
    value === '' ||
    value === false ||
    value === 0 ||
    value === '0' ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'array' && value.length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

/**
 *
 * @param {*} string
 * @returns
 */
export function generateKey(string) {
  return Number(new Date()) + Math.floor(Math.random() * 100000);
}


/**
 *
 * @param {*} value
 * @param {*} index
 * @param {*} self
 * @returns
 */
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export function onlyUniqueByKey(value, index, self, key) {
  return self.indexOf(value[key]) === index
}

export function objToQueryParams(obj) {
  if (isEmpty(obj)) return null;
  const arr = []
  Object.keys(obj).forEach(k => {
    const item = `${k}=${obj[k]}`
    arr.push(item)
  })
  return "?" + arr.join('&')
}

/**
 *
 * @param {*} date
 * @param {*} outputFormat
 * @returns
 */
export function dateTimeFormat(date, outputFormat) {
  return moment(date).format(outputFormat)
}


export function decodeLaravelValidationErrors(Errs) {
  let errors = []

  if (typeof Errs === "string") {
    return Errs;
  }

  if (typeof Errs !== 'object') return false

  if (Object.keys(Errs).length === 0) return false

  for (let key in Errs) {
    if (Errs[key].length > 0) {
      for (let ek in Errs[key]) {
        errors.push(Errs[key][ek])
      }
    }
  }

  if (errors.length === 0) return false

  return errors
}

/**
 *
 * @param {*} value
 * @returns
 */
export function isNumeric(value = null) {
  if (isEmpty(value)) {
    return false
  }
  return !isNaN(parseInt(value))
}

export function addNewFilter(existingData, newData, forceUpdate, uniqueKey) {
  forceUpdate = forceUpdate ?? false;
  uniqueKey = uniqueKey ?? 'id';
  if (!Array.isArray(existingData) || !Array.isArray(newData)) {
    return new Array(0)
  }
  let resArray = new Array(0);
  resArray = existingData.filter(a => (isEmpty(a) || a == null) ? false : !newData.some(b => !isEmpty(b) && a[uniqueKey] == b[uniqueKey]))
  return resArray.concat(newData)
}


export function dateDiff(_date1, _date2) {
  const date1 = new Date(_date1);
  const date2 = new Date(_date2);

  const Difference_In_Time = date2.getTime() - date1.getTime();

  return Difference_In_Time / (1000 * 3600 * 24);
}


export function imgLoad(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', resolve);
    image.addEventListener('error', reject);
    image.src = src;
  });
}



export function getStatusBadgeColor(status) {
  let className = "primary";
  'ACCEPTED', 'REACHED', 'PICKEDUP', 'ARRIVED', 'DELIVERED', 'COMPLETED', 'SCHEDULED', 'WAITING_FOR_PAYMENT', 'DELIVERY_REJECTED', 'OUT_FOR_DELIVERY', 'READY_FOR_PICKUP'
  switch (status) {
    case "ORDERED":
    case "RECEIVED":
      className = "secondary";
      break;

    case "STORECANCELLED":
    case "PROVIDEREJECTED":
    case "CANCELLED":
    case "DELIVERY_REJECTED":
      className = "danger";
      break;

    case "SEARCHING":
    case "PROCESSING":
      className = "dark";
      break;

    case "ASSIGNED":
    case "STARTED":
      className = "primary";
      break;

    case "ACCEPTED":
    case "REACHED":
    case "PICKEDUP":
    case "ARRIVED":
    case "SCHEDULED":
    case "OUT_FOR_DELIVERY":
      className = "info";
      break;

    case "DELIVERED":
    case "COMPLETED":
      className = "success";
      break;

    case "WAITING_FOR_PAYMENT":
    case "READY_FOR_PICKUP":
      className = "warning";
      break;

    default:
      className = "secondary";
      break;
  }
  return className
}


export const getStatusTextForRequest = (status) => {
  if (status === 'PROCESSING') return 'ON THE WAY';
  if (status === 'STARTED') return 'ACCEPT';
  if (status === 'ARRIVED') return 'ARRIVED';
  if (status === 'PICKEDUP') return 'STARTED';
  if (status === 'DROPPED') return 'TASK DONE';
  return status.replaceAll("_", " ");
}



export const convertTZ = (date, tzString) => {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}

export const thisChatUser = () => {
  let login_userable_type = null, login_userable_id = null, login_userable_name = null, login_userable_picture = null, login_userable_key = null;
  const userInfo = window.SuperApp.getters.userInfo()
  if (window.SuperApp.getters.isUser()) {
    login_userable_type = 'App\\Models\\Common\\User';
    login_userable_id = userInfo.id
    login_userable_key = `user_${login_userable_id}`;
    login_userable_name = `${userInfo.first_name} ${userInfo.last_name}`
    login_userable_picture = userInfo.picture
  } else if (window.SuperApp.getters.isProvider() && !window.SuperApp.getters.isAgent()) {
    login_userable_type = 'App\\Models\\Common\\Provider';
    login_userable_id = userInfo.id
    login_userable_key = `provider_${login_userable_id}`;
    login_userable_name = `${userInfo.company_name}`
    login_userable_picture = userInfo.picture
  } else if (window.SuperApp.getters.isProvider() && window.SuperApp.getters.isAgent()) {
    login_userable_type = 'App\\Models\\Common\\Agent';
    login_userable_id = userInfo.id
    login_userable_key = `agent_${login_userable_id}`;
    login_userable_name = `${userInfo.first_name} ${userInfo.last_name}`
    login_userable_picture = userInfo.picture
  } else if (window.SuperApp.getters.isStore()) {
    login_userable_type = 'App\\Models\\Order\\Store';
    login_userable_id = userInfo.id
    login_userable_key = `store_${login_userable_id}`;
    login_userable_name = `${userInfo.store_name}`
    login_userable_picture = userInfo.picture
  }
  return {
    type: login_userable_type,
    id: login_userable_id,
    name: login_userable_name,
    picture: login_userable_picture,
    key: login_userable_key,
  }
}