import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const modelUrl = 'user';

const state = {
    notificationCount: 0,
    notificationCountLoaded: false,
}

const getters = {
    notificationCount: state => state.notificationCount,
}

const actions = {
    getNotificationCount(ctx){
        if(ctx.state.notificationCountLoaded) return new Promise(resolve => resolve(true));
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/notifications/count`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit('getNotificationCount', data.responseData)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    getNotificationCount(state, data){
        state.notificationCount = parseInt(data.total)
        state.notificationCountLoaded = true
    },
}

export const providerBasic = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
